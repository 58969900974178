import React, { useState } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import ParcelStepper from "./parcelStepper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { trackShipment } from "../../services/trackShipment";
import "./TrackParcels.css";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const TrackParcels = () => {
  const [trackingId, setTrackingId] = useState("");
  const [parcelData, setParcelData] = useState(null);
  const [mapCenter, setMapCenter] = useState([0, 0]);
  const [mapZoom, setMapZoom] = useState(2);
  const [hasTracked, setHasTracked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setParcelData(null);
    setHasTracked(false);
    setTrackingId(e.target.value);
  };

  const handleTrack = async () => {
    if (!trackingId) {
      toast.error("Please enter a valid tracking number.");
      return;
    }
    setLoading(true);
    setParcelData(null);

    try {
      const data = await trackShipment(trackingId);
      setParcelData(data);

      // Set initial map location based on the first delivery report entry
      const lastKnownLocation = data.delivery_report[0];
      if (lastKnownLocation) {
        setMapCenter([
          parseFloat(lastKnownLocation.latitude),
          parseFloat(lastKnownLocation.longitude),
        ]);
        setMapZoom(13);
      }
      setHasTracked(true);
    } catch (err) {
      toast.error(err.message || "Failed to fetch tracking information.");
      setHasTracked(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer position="top-right" />

      <section className="page-title-area sky-blue-bg pt-160 pb-100 ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="page-title-wrapper text-center">
                <h4 className="styled-text theme-color mb-30">Got a package</h4>
                <h5 className="h1 page-title">Track your package</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid mt-5 responsive-padding">
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Enter shipment id"
            value={trackingId}
            onChange={(e) => handleInputChange(e)}
          />
          <button className="theme_btn btn-hover-style" onClick={handleTrack}>
            Track
          </button>
        </div>

        <div className="track row">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "200px" }}
            >
              <div className="text-center my-3">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-sm-12 col-lg-6">
              {parcelData ? (
                <div className="parcel-info-card">
                  <h3>{parcelData?.title}</h3>
                  <p>
                    <strong>From:</strong>{" "}
                    {parcelData.from.sender_street_address},{" "}
                    {parcelData.from.sender_city},{" "}
                    {parcelData.from.sender_country}
                  </p>
                  <p>
                    <strong>To:</strong> {parcelData.to.receiver_street_address}
                    , {parcelData.to.receiver_city},{" "}
                    {parcelData.to.receiver_country}
                  </p>
                  <h4 className="parcel-status">
                    Status:{" "}
                    {parcelData?.current_status === "completed" && (
                      <div className={` c-pill c-pill--success `}>
                        {parcelData?.current_status}
                      </div>
                    )}
                    {parcelData?.current_status === "failed" && (
                      <div className={` c-pill c-pill--danger `}>
                        {parcelData?.current_status}
                      </div>
                    )}
                    {parcelData?.current_status === "declined" && (
                      <div className={` c-pill c-pill--danger `}>
                        {parcelData?.current_status}
                      </div>
                    )}
                    {parcelData?.current_status === "pending" && (
                      <div className={` c-pill c-pill--warning `}>
                        {parcelData?.current_status}
                      </div>
                    )}
                    {parcelData?.current_status === "in progress" && (
                      <div className={` c-pill c-pill--progress `}>
                        {parcelData?.current_status}
                      </div>
                    )}
                  </h4>

                  <ParcelStepper timeline={parcelData.delivery_report} />
                </div>
              ) : (
                <p>Enter a shipment id to see parcel details.</p>
              )}
            </div>
          )}

          <div className="col-sm-12 col-lg-6">
            {hasTracked ? (
              <MapContainer
                center={mapCenter}
                zoom={mapZoom}
                style={{ minHeight: "100%", width: "100%" }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {parcelData &&
                  parcelData.delivery_report.map((report, index) => (
                    <Marker
                      key={index}
                      position={[
                        parseFloat(report.latitude),
                        parseFloat(report.longitude),
                      ]}
                    ></Marker>
                  ))}
              </MapContainer>
            ) : !loading ? (
              <div className="d-flex align-content-center">
                <img
                  src="/assets/img/no-result.jpg"
                  alt="No Tracking Data"
                  style={{
                    width: "90%",
                    height: "600px",
                    opacity: "0.4",
                    objectFit: "contain",
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackParcels;
