import React from "react";
import { Link } from "react-router-dom";
import AuthPageLayout from "./AuthPageLayout";

export default function ForgotPassword() {
  return (
    <AuthPageLayout>
      <div className="login-form ">
        <h1 className="mb-3 text-center login-text">Forgot Password</h1>
        <form>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input type="email" className="form-control" id="email" />
          </div>

          <button type="submit" className="btn btn-primary w-100 mb-3">
            Send recovery mail
          </button>

          <p className="text-center mt-3">
            Remembered your password?{" "}
            <Link to="/signup" className="create-account">
              Login
            </Link>
          </p>
        </form>
      </div>
    </AuthPageLayout>
  );
}
