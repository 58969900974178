// import React from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   useLocation,
// } from "react-router-dom";
// import "./App.css";
// import MainComponent from "./components/Main";
// import Footer from "./components/Footer/Footer";
// import Header from "./components/Header/Header";
// import QuoteForm from "./components/QuoteForm/QuoteForm";
// import ScrollToTop from "./components/ui/ScrollToTop";
// import TrackParcels from "./components/TrackParcel/TrackParcels";
// import LoginPage from "./components/Auth/LoginPage";
// import SignUp from "./components/Auth/SignUp";
// import Service from "./components/AllService/Service";
// import ForgotPassword from "./components/Auth/ForgotPassword";
// import Layout from "./Dashboard/Users/Layout";
// import Shipment from "./Dashboard/Users/Pages/Shipment";

// function App() {
//   const location = useLocation();
//   const noHeaderFooterRoutes = [
//     "/login",
//     "/signup",
//     "/forgot-password",
//     "/app",
//   ];

//   const shouldRenderHeaderFooter = !noHeaderFooterRoutes.includes(
//     location.pathname
//   );

//   return (
//     <div className="App">
//       <ScrollToTop />
//       {shouldRenderHeaderFooter && <Header />}
//       <Routes>
//         <Route path="/" element={<MainComponent />} />{" "}
//         <Route path="/quote/:service" element={<QuoteForm />} />{" "}
//         <Route path="/trackparcels" element={<TrackParcels />} />
//         <Route path="/login" element={<LoginPage />} />
//         <Route path="/signup" element={<SignUp />} />
//         <Route path="/all-services" element={<Service />} />
//         <Route path="/forgot-password" element={<ForgotPassword />} />
//         <Route path="/app" element={<Layout />} />
//       </Routes>
//       {shouldRenderHeaderFooter && <Footer />}
//     </div>
//   );
// }

// function AppWrapper() {
//   return (
//     <Router>
//       <App />
//     </Router>
//   );
// }

// export default AppWrapper;

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import MainComponent from "./components/Main";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import QuoteForm from "./components/QuoteForm/QuoteForm";
import ScrollToTop from "./components/ui/ScrollToTop";
import TrackParcels from "./components/TrackParcel/TrackParcels";
import LoginPage from "./components/Auth/LoginPage";
import SignUp from "./components/Auth/SignUp";
import Service from "./components/AllService/Service";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Layout from "./Dashboard/Users/Layout";
import Loader from "./components/Loader/Loader";
import ProtectedRoutes from "./utils/ProtectedRoutes";

function App() {
  const location = useLocation();
  const noHeaderFooterRoutes = [
    "/login",
    "/signup",
    "/forgot-password",
    "/app",
  ];

  const shouldRenderHeaderFooter = !noHeaderFooterRoutes.includes(
    location.pathname
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true); // Show loader when component is mounting

    // Set loading to false after a brief delay (adjust this as necessary)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Simulate loading time, replace with actual loading logic

    return () => clearTimeout(timer);
  }, [location.pathname]); // Re-run effect on route change

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="App">
      <ScrollToTop />
      {shouldRenderHeaderFooter && <Header />}
      <Routes>
        <Route path="/" element={<MainComponent />} />
        <Route path="/quote/:service" element={<QuoteForm />} />
        <Route path="/trackparcels" element={<TrackParcels />} />

        <Route element={<ProtectedRoutes />}>
          <Route path="/app" element={<Layout />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/all-services" element={<Service />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Routes>
      {shouldRenderHeaderFooter && <Footer />}
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
