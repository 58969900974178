import React from "react";

const Features = () => {
  return (
    <section className="grey-bg-soft about-copic position-relative pb-150 pt-150 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">
      <div className="container">
        <div className="row">
          <div className="col-md-4 text-center">
            <img
              src="assets/img/rannking.png"
              style={{ width: "200px" }}
              alt="Ranking"
            />
            <h3 className="mb-25">Ranking</h3>
            <p style={{ fontSize: "18px" }}>
              See logistic company rankings based on cost, geographical
              coverage, product type, and delivery times.
            </p>
          </div>
          <div className="col-md-4 text-center">
            <img
              src="assets/img/img2x.png"
              style={{ width: "200px" }}
              alt="Saving Time"
            />
            <h3 className="mb-25">Saving Time</h3>
            <p style={{ fontSize: "18px" }}>
              We save you the trouble of having to individually shop around for
              the best quotes. You see all your results on a dashboard, helping
              you make up your mind in no time.
            </p>
          </div>
          <div className="col-md-4 text-center">
            <img
              src="assets/img/spx.png"
              style={{ width: "200px" }}
              alt="Value Added Reseller"
            />
            <h3 className="mb-25">Value Added Reseller</h3>
            <p style={{ fontSize: "18px" }}>
              We provide a wide range of services deeply discounted at a
              fraction of what you would normally get.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
