import React from "react";

export default function Select({
  data,
  label,
  value,
  onChange,
  name,
  ...props
}) {
  const handleSelect = (item) => {
    // Trigger the onChange function passed as a prop
    if (onChange) {
      onChange({
        target: {
          name,
          value: item,
        },
      });
    }
  };

  return (
    <div className="quantity q-style1 me-4 mb-1" style={{ zIndex: "99" }}>
      <select
        style={{ display: "none", width: "100%" }}
        value={value}
        {...props}
      >
        <option value="">{label}</option>
        {data.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
      <div className="nice-select capitalize w-100" tabIndex={0}>
        <span className="current">{value}</span>
        <ul className="list" style={{ zIndex: "99" }}>
          {data.map((item) => (
            <li
              key={item}
              data-value={item}
              className="option capitalize"
              onClick={() => handleSelect(item)}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
