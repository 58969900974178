import React, { useEffect } from "react";

const Carousel = () => {
  useEffect(() => {
    const $ = window.$; // Ensure jQuery is available
    if (!$) {
      console.error("jQuery is not loaded");
      return;
    }

    const carousel = $(".owl-carousel").owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      autoplay: true,
      autoplayTimeout: 3000,
      responsive: {
        0: { items: 2 },
        600: { items: 3 },
        1000: { items: 6 },
      },
    });

    // Cleanup function
    return () => {
      carousel.trigger("destroy.owl.carousel");
    };
  }, []);

  return (
    <div className="container mt-5">
      <div className="owl-carousel owl-theme">
        <div className="item">
          <center>
            <img src="assets/img/partners/1.png" alt="" className="img-fluid" />
          </center>
        </div>
        <div className="item">
          <center>
            <img src="assets/img/partners/2.png" alt="" className="img-fluid" />
          </center>
        </div>
        <div className="item">
          <center>
            <img src="assets/img/partners/3.png" alt="" className="img-fluid" />
          </center>
        </div>
        <div className="item">
          <center>
            <img src="assets/img/partners/4.png" alt="" className="img-fluid" />
          </center>
        </div>
        <div className="item">
          <center>
            <img src="assets/img/partners/5.png" alt="" className="img-fluid" />
          </center>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
