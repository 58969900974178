import axios from "axios";
const BASE_URL = "https://api.shipperswise.com";

export const shipmentDetails = async (shipmentId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/specific-shipments-details?shipment_id=${shipmentId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error);
  }
};
