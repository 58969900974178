import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useState } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import ParcelStepper from "../../../components/TrackParcel/parcelStepper";
import { Link } from "react-router-dom";
import { trackShipment } from "../../../services/trackShipment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../components/TrackParcel/TrackParcels.css";

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const ParcelTracking = () => {
  const [trackingId, setTrackingId] = useState("");
  const [parcelData, setParcelData] = useState(null);
  const [mapCenter, setMapCenter] = useState([0, 0]);
  const [mapZoom, setMapZoom] = useState(2);
  const [hasTracked, setHasTracked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleTrack = async () => {
    if (!trackingId) {
      toast.error("Please enter a valid tracking number.");
      return;
    }
    setLoading(true);
    setParcelData(null);

    try {
      const data = await trackShipment(trackingId);
      setParcelData(data);

      // Set initial map location based on the first delivery report entry
      const lastKnownLocation = data.delivery_report[0];
      if (lastKnownLocation) {
        setMapCenter([
          parseFloat(lastKnownLocation.latitude),
          parseFloat(lastKnownLocation.longitude),
        ]);
        setMapZoom(13);
      }
      setHasTracked(true);
    } catch (err) {
      toast.error(err.message || "Failed to fetch tracking information.");
      setHasTracked(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="">
      <ToastContainer />
      <div className="dashboard-title">
        <h1 className="page-title">Track your Parcels</h1>

        <span>
          Enter your shipping ID below to get real-time updates on the status
          and location of your shipment. Stay informed and know exactly when
          your package will arrive.
        </span>

        <div className="row  mb-5 mt-4">
          <div className="col-md-6">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter shipping ID"
                value={trackingId}
                onChange={(e) => setTrackingId(e.target.value)}
              />
              <button
                className="theme_btn btn-hover-style"
                onClick={handleTrack}
                disabled={loading}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  ></span>
                ) : (
                  "Track"
                )}
              </button>
            </div>
          </div>
          {!parcelData && (
            <p>
              Can't find your shipping ID? <Link to="">Click here</Link>
            </p>
          )}
        </div>
      </div>

      {parcelData ? (
        <div className="second-section">
          <div className="row mb-5">
            <div className="col-lg-6">
              <div className="card shadow-sm mb-4">
                <div className="card-body parcel-info">
                  <h5 className="card-title">Order Details</h5>

                  <h3 style={{ fontSize: "20px", marginTop: "12px" }}>
                    {parcelData?.title}
                  </h3>
                  <p>
                    <strong>From:</strong>{" "}
                    {parcelData?.from.sender_street_address},{" "}
                    {parcelData?.from.sender_city},{" "}
                    {parcelData?.from.sender_country}
                  </p>
                  <p>
                    <strong>To:</strong>{" "}
                    {parcelData?.to.receiver_street_address},{" "}
                    {parcelData?.to.receiver_city},{" "}
                    {parcelData?.to.receiver_country}
                  </p>
                  <h4 className="parcel-status">
                    Status:{" "}
                    {parcelData?.current_status === "completed" && (
                      <div className={` c-pill c-pill--success `}>
                        {parcelData?.current_status}
                      </div>
                    )}
                    {parcelData?.current_status === "failed" && (
                      <div className={` c-pill c-pill--danger `}>
                        {parcelData?.current_status}
                      </div>
                    )}
                    {parcelData?.current_status === "declined" && (
                      <div className={` c-pill c-pill--danger `}>
                        {parcelData?.current_status}
                      </div>
                    )}
                    {parcelData?.current_status === "pending" && (
                      <div className={` c-pill c-pill--warning `}>
                        {parcelData?.current_status}
                      </div>
                    )}
                    {parcelData?.current_status === "in progress" && (
                      <div className={` c-pill c-pill--progress `}>
                        {parcelData?.current_status}
                      </div>
                    )}
                  </h4>

                  <h4
                    className="mb-2 mt-3 card-title"
                    style={{ fontSize: "18px" }}
                  >
                    Delivery Status
                  </h4>
                  <ParcelStepper timeline={parcelData?.delivery_report} />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              {hasTracked ? (
                <MapContainer
                  center={mapCenter}
                  zoom={mapZoom}
                  style={{ height: "500px", width: "100%" }}
                  className="shadow-sm"
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  {parcelData &&
                    parcelData.delivery_report.map((report, index) => (
                      <Marker
                        key={index}
                        position={[
                          parseFloat(report.latitude),
                          parseFloat(report.longitude),
                        ]}
                      ></Marker>
                    ))}
                </MapContainer>
              ) : (
                <div className="text-center">
                  <img
                    src="/assets/img/no-result.jpg"
                    alt="No Tracking Data"
                    style={{
                      width: "80%",
                      height: "auto",
                      opacity: "0.7",
                      objectFit: "contain",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ParcelTracking;
