import React from "react";
import { useNavigate } from "react-router-dom";
import "./AuthModal.css"; // Optional: Import CSS for styling the modal

const AuthModal = ({ show, onClose }) => {
  const navigate = useNavigate();

  if (!show) {
    return null;
  }

  const handleLogin = () => {
    navigate("/login");
    onClose();
  };

  const handleCreateAccount = () => {
    navigate("/signup");
    onClose();
  };

  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal">
        <div>
          <h2>Login or Create an Account</h2>
          <p>You need to log in or create an account to submit your request.</p>
        </div>

        <div className="auth-modal-buttons">
          <button
            className="theme_btn_border login"
            style={{ fontSize: "24px" }}
            onClick={handleLogin}
          >
            Log In
          </button>
          <button className="theme_btn" onClick={handleCreateAccount}>
            Create an Account
          </button>
        </div>
        <button className="close-modal" onClick={onClose}>
          &times;
        </button>
      </div>
    </div>
  );
};

export default AuthModal;
