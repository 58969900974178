import React from "react";
import "./Card.css";

export default function Card({ title, stat, icon, bgCol, divCol }) {
  return (
    <>
      <div className="card custom-card shadow-lg">
        <div
          className="card-body"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            padding: "40px 30px",
          }}
        >
          <div
            className="icon-div"
            style={{
              backgroundColor: bgCol,
              color: divCol,
            }}
          >
            <i className="material-icons-outlined ">{icon}</i>
          </div>
          <div>
            <h5 className="title-card">{title}</h5>
            <p className="h2 stat">{stat}</p>
          </div>
        </div>
      </div>
    </>
  );
}
