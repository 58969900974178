import React from "react";
import { Link } from "react-router-dom";

export default function Logo({ small }) {
  return (
    <div className="logo-img">
      <Link to="/">
        {small ? (
          <img
            className="img-fluid"
            src="/assets/img/logo-small.png"
            style={{ width: "" }}
            alt=""
          />
        ) : (
          <img
            className="img-fluid"
            src="/assets/img/logo-main.png"
            style={{ width: "200px" }}
            alt=""
          />
        )}
      </Link>
    </div>
  );
}
