import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS
import "./Dashboard.css"; // Assuming you create a CSS file for custom styles
import Card from "../Components/Card";
import DashboardChart from "../Components/DashboardChart";
import Table from "../Components/Table";

const Dashboard = ({ loading, dashboardStats, recentShipment }) => {
  return (
    <div className="page-container">
      <ToastContainer />
      <div className="dashboard-title">
        <div className="d-flex justify-content-between">
          <div>
            <h1 className="page-title">Dashboard</h1>
            <span>
              The dashboard offers a clear and concise overview of your
              logistics operations, featuring statistics on pending, completed,
              and total shipments. Additionally, it includes a chart that
              displays monthly shipment data for this year, providing valuable
              insights to help you track performance.
            </span>
          </div>

          <div className="chart-svg" style={{ width: "450px", height: "auto" }}>
            <svg
              className="w-full h-full"
              viewBox="0 0 400 300"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="400" height="300" fill="none" />
              <path d="M50 250 L350 250" stroke="#D1D5DB" strokeWidth="2" />
              <path d="M200 50 L200 250" stroke="#D1D5DB" strokeWidth="2" />
              <circle
                cx="200"
                cy="150"
                r="100"
                fill="#60A5FA"
                fillOpacity="0.2"
              />
              <path
                d="M150 200 Q200 100 250 200"
                stroke="#2563EB"
                strokeWidth="3"
                fill="none"
              />
              <circle cx="150" cy="200" r="5" fill="#2563EB" />
              <circle cx="250" cy="200" r="5" fill="#2563EB" />
              <path
                d="M100 230 L140 190 L180 210 L220 170 L260 190 L300 150"
                stroke="#10B981"
                strokeWidth="3"
                fill="none"
              />
              <rect
                x="175"
                y="125"
                width="50"
                height="30"
                rx="5"
                fill="#F59E0B"
              />
              <rect x="185" y="135" width="30" height="10" fill="white" />
            </svg>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6  mb-3">
          <Card
            icon="local_shipping"
            title="Total Shipment"
            stat={dashboardStats?.total_shipments}
            bgCol="#DFF0FE"
            divCol="#1b83c5"
          />
        </div>
        {/* <div className="col-12 col-md-6 col-lg-4 mb-3">
          <Card
            icon="electric_rickshaw"
            title="In Progress"
            stat="56"
            bgCol="#fff3e3"
            divCol="#ff9500"
          />
        </div> */}
        <div className="col-12 col-md-6  mb-3">
          <Card
            icon="add_task"
            title="Completed Shipments"
            stat={dashboardStats?.total_completed}
            bgCol="#e6fcf0"
            divCol="#4bad48"
          />
        </div>
        {/* <div className="col-12 col-md-6 col-lg-4 mb-3">
          <Card
            icon="pending_actions"
            title="Pending"
            stat="22"
            bgCol="#ffefef"
            divCol="#ef4444"
          />
        </div> */}
      </div>
      <div className="mt-4 mb--10">
        <DashboardChart chartData={dashboardStats?.monthly_stats} />
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <div className=" custom-card shadow-lg">
            <div className="card-header table-head text-white">
              Recent Shipments
            </div>
            <div className="card-body">
              <Table data={recentShipment} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
