import React from "react";

export default function ParcelStepper({ timeline }) {
  const activeStep = timeline?.findIndex((event) => !event.time_out) || 0;

  return (
    <div className="parcel-stepper">
      {timeline?.map((event, index) => (
        <div
          key={index}
          className={`stepper-item ${index <= activeStep ? "completed" : ""} ${
            index === activeStep ? "active" : ""
          }`}
        >
          <div className="stepper-content">
            <div className="stepper-circle">{index + 1}</div>
            <div className="stepper-info">
              <strong className="stepper-time">{event.time_in}</strong>
              <p className="stepper-status">{event.description}</p>
            </div>
          </div>
          {index < timeline.length - 1 && <div className="stepper-line"></div>}
        </div>
      ))}

      <style jsx>{`
        .parcel-stepper {
          position: relative;
          padding: 5px 0;
        }

        .stepper-item {
          position: relative;
          padding-bottom: 10px;
        }

        .stepper-content {
          display: flex;
          align-items: flex-start;
        }

        .stepper-circle {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: #e9ecef;
          color: #6c757d;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          margin-right: 15px;
          z-index: 1;
        }

        .stepper-info {
          flex-grow: 1;
        }

        .stepper-time {
          display: block;
          margin-bottom: 5px;
        }

        .stepper-status {
          margin-bottom: 5px;
        }

        .stepper-item.completed .stepper-circle {
          background-color: #28a745;
          color: white;
        }

        .stepper-item.completed .stepper-line {
          background-color: #28a745;
        }

        .stepper-item.active .stepper-circle {
          background-color: #007bff;
          color: white;
        }

        .stepper-item:last-child .stepper-line {
          display: none;
        }
      `}</style>
    </div>
  );
}
