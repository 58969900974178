import React, { useEffect, useState } from "react";
import ChangePassword from "../Pages/ChangePassword";
import Dashboard from "../Pages/Dashboard";
import EmailNotifications from "../Pages/EmailNotification";
import GetQuotes from "../Pages/GetQuotes";
import ParcelTracking from "../Pages/ParcelTracking";
import Profile from "../Pages/Profile";
import Quotes from "../Pages/Quotes";
import Shipment from "../Pages/Shipment";
import userService from "../../../services/userService";

const MainContent = ({ isSidebarOpen, activeMenuItem, setActiveMenuItem }) => {
  const [trackID, setTrackID] = useState("");

  const handleTrack = (trackingId) => {
    console.log(trackingId);
    setTrackID(trackingId);
    console.log(trackID, trackingId);
    setActiveMenuItem("parcel-tracking");
  };

  const [loading, setLoading] = useState(false);
  const [dashboardStats, setDashboardStats] = useState(null);
  const [recentShipment, setRecentShipment] = useState([]);
  const [shipment, setShipment] = useState([]);
  const [error, setError] = useState(null);

  const fetchDashboardStats = async () => {
    setLoading(true);
    try {
      const data = await userService.getDashboardStats();
      setDashboardStats(data);
      console.log(data);
      setError(null);
    } catch (error) {
      console.error("Failed to load dashboard stats:", error);
      if (error.message === "Network Error") {
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchRecentShipments = async () => {
    setLoading(true);
    try {
      const data = await userService.getUserRecentShipments();
      setRecentShipment(data);
      console.log(data);
      setError(null);
    } catch (error) {
      console.error("Failed to load recent shipments:", error);
      if (error.message === "Network Error") {
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchShipments = async () => {
    setLoading(true);
    try {
      const data = await userService.getUserShipments();
      setShipment(data);
      console.log("shipment data", { data });
      setError(null);
    } catch (error) {
      console.error("Failed to load recent shipments:", error);

      if (error.message === "Network Error") {
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardStats();
    fetchRecentShipments();
    fetchShipments();
  }, [error]);

  const renderContent = () => {
    switch (activeMenuItem) {
      case "dashboard":
        return (
          <Dashboard
            loading={loading}
            dashboardStats={dashboardStats}
            recentShipment={recentShipment}
          />
        );
      case "all-shipments":
        return (
          <Shipment
            title="All Shipments"
            description="This page provides a comprehensive view of all shipments, including those that are in progress, failed, declined, pending and completed. Track the status of all their shipments in one centralized location."
            onTrack={handleTrack}
            shipmentData={shipment}
          />
        );
      case "pending-shipments":
        return (
          <Shipment
            title="Pending Shipments"
            description="This page displays a table listing all pending shipments. You can easily track the progress of your pending shipments and manage them efficiently."
            onTrack={handleTrack}
            shipmentData={shipment}
          />
        );
      case "in-progress-shipments":
        return (
          <Shipment
            title="In Progress Shipments"
            description="This page displays a table listing all shipments in progress. You can easily track the progress of your shipments in progress and manage them efficiently."
            onTrack={handleTrack}
            shipmentData={shipment}
          />
        );
      case "failed-shipments":
        return (
          <Shipment
            title="Failed Shipments"
            description="This page displays a table listing all failed shipments. You can easily track the progress of your failed shipments and manage them efficiently."
            onTrack={handleTrack}
            shipmentData={shipment}
          />
        );
      case "declined-shipments":
        return (
          <Shipment
            title="Declined Shipments"
            description="This page displays a table listing all declined shipments. You can easily track the progress of your declined shipments and manage them efficiently."
            onTrack={handleTrack}
            shipmentData={shipment}
          />
        );
      case "completed-shipments":
        return (
          <Shipment
            title="Completed Shipments"
            description="This page presents a table featuring all shipments that have been successfully delivered."
            onTrack={handleTrack}
            shipmentData={shipment}
          />
        );
      case "parcel-tracking":
        if (trackID) {
          return (
            <ParcelTracking
              trackID={trackID}
              onTrackingComplete={(trackingId) => {
                setTrackID(null);
              }}
            />
          );
        } else {
          return <ParcelTracking />;
        }
      case "quotes":
        return <Quotes shipmentData={shipment} />;
      case "get-quotes":
        return <GetQuotes />;
      case "profile":
        return <Profile />;
      case "email-notification":
        return <EmailNotifications />;
      case "change-password":
        return <ChangePassword />;
      case "settings":
        return <h2>Settings Content</h2>;
      default:
        return <h2>Select a menu item</h2>;
    }
  };

  return (
    <div className={`main-content ${isSidebarOpen ? "sidebar-open" : ""}`}>
      <div className="">{renderContent()}</div>
    </div>
  );
};

export default MainContent;
