import React, { useState } from "react";
import { Package, Plane, Ship, Train, Truck, Home } from "lucide-react";
import { useNavigate } from "react-router-dom";

const Solutions = () => {
  const [selectedTab, setSelectedTab] = useState("Courier");
  const navigate = useNavigate();

  const handleServiceClick = () => {
    navigate(`/quote/${selectedTab.toLowerCase().replace(/\s+/g, "-")}`);
  };

  const tabs = [
    {
      title: "Courier",
      subtitle: "Delivers packages quickly and securely.",
      icon: Package,
    },
    {
      title: "Air Freight",
      subtitle: "Transports goods swiftly by air.",
      icon: Plane,
    },
    {
      title: "Sea Freight",
      subtitle: "Ships large cargo across oceans.",
      icon: Ship,
    },
    {
      title: "Rail Freight",
      subtitle: "Transports heavy goods long distances.",
      icon: Train,
    },
    {
      title: "Road Transport",
      subtitle: "Move goods by land efficiently.",
      icon: Truck,
    },
    { title: "Relocation", subtitle: "Relocation services.", icon: Home },
  ];

  const tabContent = {
    Courier: {
      header: "Courier",
      text: "We are your e-market place for shipping documents, parcels, and pallets, saving you time and cost on price comparison across multiple courier services. With us, one request gives you multiple solutions.",
      img: "/assets/img/shipperswisePics/courier.jpg",
    },
    "Air Freight": {
      header: "Air Freight",
      text: "We aim to provide global Air freight quotes at the touch of a button, enabling you to send your cargo with shorter transit times using trusted partners.",
      img: "/assets/img/shipperswisePics/air.jpg",
    },
    "Sea Freight": {
      header: "Sea Freight",
      text: "We solve the complexities of international ocean freights by bringing global logistics partners to your virtual doorstep.",
      img: "/assets/img/shipperswisePics/ship.jpg",
    },
    "Rail Freight": {
      header: "Rail Freight",
      text: "We are well suited to get our customers the best rail freight quotes, saving costs and planning the best routes for each shipment.",
      img: "/assets/img/shipperswisePics/train.jpg",
    },
    "Road Transport": {
      header: "Road Transport",
      text: "We provide access to direct contacts with our partners by offering door-to-door delivery by air, sea, and land.",
      img: "/assets/img/shipperswisePics/truck.jpg",
    },
    Relocation: {
      header: "Relocation",
      text: "We offer a bespoke service that makes relocation easier, safer, and more flexible, getting your belongings to your destination.",
      img: "/assets/img/shipperswisePics/relocation.jpg",
    },
  };

  return (
    <div className="row justify-content-center" style={{ marginTop: "100px" }}>
      <div className="col-xl-7">
        <div className="section-title section-title2 text-center mb-80">
          <h2 className="mb-20">Our Solutions</h2>
          <p>Discover a new era of international freight with Shipperswise.</p>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {/* Horizontal Tab Header */}
          <div className="col-12">
            <div
              className="tab-header d-flex justify-content-center mb-5"
              style={{ borderBottom: "2px solid #D5DADF", overflowX: "auto" }}
            >
              {tabs.map((tab) => (
                <button
                  key={tab.title}
                  onClick={() => setSelectedTab(tab.title)}
                  className={`tab-item px-4 py-2 text-center ${
                    selectedTab === tab.title ? "active" : ""
                  }`}
                  style={{
                    borderBottom:
                      selectedTab === tab.title
                        ? "3px solid #000067"
                        : "3px solid transparent",
                    color: selectedTab === tab.title ? "#000067" : "#333",
                    background: "none",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingBottom: "10px",
                    margin: "0 20px",
                    fontSize: "1.2rem",
                  }}
                >
                  <tab.icon
                    className={`h-8 w-8 mb-2 ${
                      selectedTab === tab.title
                        ? "text-blue-500"
                        : "text-gray-500"
                    }`}
                  />
                  <span>{tab.title}</span>
                </button>
              ))}
            </div>
          </div>

          {/* Tab Content */}
          <div className="col-12 d-flex flex-column align-items-center justify-content-center pt-50 pb-50">
            <div
              className="row align-items-center justify-content-between"
              style={{ maxWidth: "1200px", width: "100%" }}
            >
              {/* Image on the left (will stack on small screens) */}
              <div className="col-12 col-md-6 order-1 order-md-1">
                <img
                  src={tabContent[selectedTab].img}
                  alt={tabContent[selectedTab].header}
                  style={{
                    width: "80%",
                    height: "auto",
                    marginBottom: "20px",
                  }}
                />
              </div>

              {/* Text and button on the right (will stack on small screens) */}
              <div className="col-12 col-md-6 order-2 order-md-2 d-flex flex-column align-items-start justify-content-center text-left">
                <h3
                  className="tab-content__header mb-4"
                  style={{ fontSize: "2rem" }}
                >
                  {tabContent[selectedTab].header}
                </h3>
                <p
                  className="tab-content__text mt-2"
                  style={{ fontSize: "1.1rem", marginBottom: "20px" }}
                >
                  {tabContent[selectedTab].text}
                </p>
                <button
                  className="theme_btn btn-hover-style mt-4 mb-25"
                  onClick={handleServiceClick}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
