import axios from "axios";

const BASE_URL = "https://api.shipperswise.com";

const token = localStorage.getItem("token");

const sendCourierRequest = async (formData) => {
  const courierRequestData = {
    sender_first_name: formData.senderFirstName,
    sender_last_name: formData.senderLastName,
    sender_phone: formData.senderPhoneNumber,
    sender_email: formData.senderEmail,
    sender_country: formData.senderCountry,
    sender_city: formData.senderCity,
    sender_postal_code: formData.senderPostalCode,
    sender_street_address: formData.senderStreet,
    receiver_first_name: formData.receiverFirstName,
    receiver_last_name: formData.receiverLastName,
    receiver_phone: formData.receiverPhoneNumber,
    receiver_email: formData.receiverEmail,
    receiver_country: formData.receiverCountry,
    receiver_city: formData.receiverCity,
    receiver_postal_code: formData.receiverPostalCode,
    receiver_street_address: formData.receiverStreet,
    number_of_packages: formData.packageCount,
    package_description: formData.packageDescription,
    weight: formData.weight,
    length: formData.length,
    width: formData.width,
    height: formData.height,
    service_type: formData.serviceType,
    pick_up_date: formData.pickupDateTime,
    preferred_currency: formData.preferredCurrency,
    payment_method: formData.paymentMethod,
    additional_info: formData?.additionalInfo || "",
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/courier-requests`,
      courierRequestData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to send courier request"
    );
  }
};

const sendAirFreightRequest = async (formData) => {
  const airFreightRequestData = {
    sender_first_name: formData.senderFirstName,
    sender_last_name: formData.senderLastName,
    sender_phone: formData.senderPhoneNumber,
    sender_email: formData.senderEmail,
    sender_country: formData.senderCountry,
    sender_city: formData.senderCity,
    sender_postal_code: formData.senderPostalCode,
    sender_street_address: formData.senderStreet,
    receiver_first_name: formData.receiverFirstName,
    receiver_last_name: formData.receiverLastName,
    receiver_phone: formData.receiverPhoneNumber,
    receiver_email: formData.receiverEmail,
    receiver_country: formData.receiverCountry,
    receiver_city: formData.receiverCity,
    receiver_postal_code: formData.receiverPostalCode,
    receiver_street_address: formData.receiverStreet,
    number_of_packages: formData.packageCount,
    type_of_goods: formData.typeOfGoods,
    weight: formData.weight,
    length: formData.length,
    width: formData.width,
    height: formData.height,
    value_of_goods: formData.valueOfGoods,
    preferred_shipping_date: formData.shippingDate,
    destination: formData.destinationAirport,
    preferred_currency: formData.preferredCurrency,
    payment_method: formData.paymentMethod,
    additional_info: formData?.additionalInfo || "Nothing",
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/air-freight-requests`,
      airFreightRequestData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to send air freight request"
    );
  }
};

const sendSeaFreightRequest = async (formData) => {
  const seaFreightRequestData = {
    sender_first_name: formData.senderFirstName,
    sender_last_name: formData.senderLastName,
    sender_phone: formData.senderPhoneNumber,
    sender_email: formData.senderEmail,
    sender_country: formData.senderCountry,
    sender_city: formData.senderCity,
    sender_postal_code: formData.senderPostalCode,
    sender_street_address: formData.senderStreet,
    receiver_first_name: formData.receiverFirstName,
    receiver_last_name: formData.receiverLastName,
    receiver_phone: formData.receiverPhoneNumber,
    receiver_email: formData.receiverEmail,
    receiver_country: formData.receiverCountry,
    receiver_city: formData.receiverCity,
    receiver_postal_code: formData.receiverPostalCode,
    receiver_street_address: formData.receiverStreet,
    container_type: formData.containerType,
    type_of_goods: formData.typeOfGoods,
    number_of_containers: formData.numberOfContainer,
    weight: formData.weight,
    value_of_goods: formData.valueOfGoods,
    preferred_shipping_date: formData.shippingDate,
    destination_port: formData.destinationPort,
    shipping_mode: formData.shippingMode,
    preferred_currency: formData.preferredCurrency,
    payment_method: formData.paymentMethod,
    additional_info: formData.additionalInfo,
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/sea-freight-requests`,
      seaFreightRequestData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to send sea freight request"
    );
  }
};
const sendRailFreightRequest = async (formData) => {
  const railFreightRequestData = {
    sender_first_name: formData.senderFirstName,
    sender_last_name: formData.senderLastName,
    sender_phone: formData.senderPhoneNumber,
    sender_email: formData.senderEmail,
    sender_country: formData.senderCountry,
    sender_city: formData.senderCity,
    sender_postal_code: formData.senderPostalCode,
    sender_street_address: formData.senderStreet,
    receiver_first_name: formData.receiverFirstName,
    receiver_last_name: formData.receiverLastName,
    receiver_phone: formData.receiverPhoneNumber,
    receiver_email: formData.receiverEmail,
    receiver_country: formData.receiverCountry,
    receiver_city: formData.receiverCity,
    receiver_postal_code: formData.receiverPostalCode,
    receiver_street_address: formData.receiverStreet,
    type_of_goods: formData.typeOfGoods,
    number_of_palettes: formData.numberOfContainer,
    weight: formData.weight,
    length: formData.length,
    width: formData.width,
    height: formData.height,
    preferred_shipping_date: formData.prefferredShippingDate,
    destination_station: formData.destinationStation,
    preferred_currency: formData.preferredCurrency,
    payment_method: formData.paymentMethod,
    additional_info: formData.additionalInfo,
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/rail-freight-requests`,
      railFreightRequestData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to send rail freight request"
    );
  }
};
const sendRoadTransportRequest = async (formData) => {
  const roadTransportRequestData = {
    sender_first_name: formData.senderFirstName,
    sender_last_name: formData.senderLastName,
    sender_phone: formData.senderPhoneNumber,
    sender_email: formData.senderEmail,
    sender_country: formData.senderCountry,
    sender_city: formData.senderCity,
    sender_postal_code: formData.senderPostalCode,
    sender_street_address: formData.senderStreet,
    receiver_first_name: formData.receiverFirstName,
    receiver_last_name: formData.receiverLastName,
    receiver_phone: formData.receiverPhoneNumber,
    receiver_email: formData.receiverEmail,
    receiver_country: formData.receiverCountry,
    receiver_city: formData.receiverCity,
    receiver_postal_code: formData.receiverPostalCode,
    receiver_street_address: formData.receiverStreet,
    type_of_goods: formData.typeOfGoods,
    number_of_packages: formData.packageCount,
    value_of_goods: formData.valueOfGoods,
    weight: formData.weight,
    length: formData.length,
    width: formData.width,
    height: formData.height,
    preferred_pickup_date: formData.preferredPickUpDate,
    preferred_shipping_date: formData.shippingDate,
    destination_port: formData.deliveryStation,
    vehicle_type: formData.vehicleType,
    preferred_currency: formData.preferredCurrency,
    payment_method: formData.paymentMethod,
    additional_info: formData.additionalInfo,
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/road-requests`,
      roadTransportRequestData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to send road transport request"
    );
  }
};
const sendRelocationRequest = async (formData) => {
  const roadTransportRequestData = {
    from_country: formData.countryFrom,
    from_city: formData.cityFrom,
    from_postal_code: formData.postalCodeFrom,
    from_address: formData.addressFrom,
    to_country: formData.countryTo,
    to_city: formData.cityTo,
    to_postal_code: formData.postalCodeTo,
    to_address: formData.addressTo,
    type_of_relocation: formData.typeOfRelocation,
    list_of_items: formData.listOfItems,
    preferred_moving_date: formData.preferredMovingDate,
    preferred_currency: formData.preferredCurrency,
    payment_method: formData.paymentMethod,
    additional_info: formData.additionalInfo,
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/relocation-requests`,
      roadTransportRequestData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to send road transport request"
    );
  }
};

export const sendRequest = async (serviceType, formData) => {
  switch (serviceType) {
    case "courier":
      return await sendCourierRequest(formData);
    case "air-freight":
      return await sendAirFreightRequest(formData);
    case "sea-freight":
      return await sendSeaFreightRequest(formData);
    case "rail-freight":
      return await sendRailFreightRequest(formData);
    case "road-transport":
      return await sendRoadTransportRequest(formData);
    case "relocation":
      return await sendRelocationRequest(formData);
    default:
      throw new Error("Invalid service type");
  }
};
