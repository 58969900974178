import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export default function ProtectedRoutes() {
  //   const [token, setToken] = useState(null);

  //   useEffect(() => {
  const storedToken = localStorage.getItem("token");
  //     console.log("token", storedToken);
  //     setToken(storedToken);
  //   }, [token]);

  return storedToken ? <Outlet /> : <Navigate to="/login" />;
}
