import React from "react";

export default function Input({ type, ...props }) {
  return (
    <div className="quantity q-style1 me-4 mb-1">
      <input
        type={type}
        className="qty-input "
        {...props}
        min={type === "number" && "0"}
      />
    </div>
  );
}
