import React from "react";
import { Link } from "react-router-dom";
import "./AuthPage.css";

const AuthPageLayout = ({ children }) => {
  return (
    <div className="container-fluid vh-100">
      <div className="row h-100 position-relative">
        <div className="col-md-6 purple-bg d-flex flex-column align-items-start p-4 left">
          <div className="logo-img" style={{ zIndex: "99" }}>
            <Link to="/">
              <img
                className="img-fluid"
                src="/assets/img/logo-main.png"
                style={{ width: "200px", zIndex: "99" }}
                alt=""
              />
            </Link>
          </div>
          <div className="pageImage">
            <img className="" src="/assets/img/space-craft.png" alt="" />
          </div>
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-center right">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthPageLayout;
