let pendingData = {};
let service = "";

export const setPendingData = (pendingFormData, serviceInfo) => {
  pendingData = { pendingFormData };
  service = serviceInfo;

  console.log({ pendingData, service });
};

export const getPendingData = () => {
  return { pendingData, service };
};
