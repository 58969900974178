import React from "react";
import "./RequestModal.css";

const RequestModal = ({ show, onClose, loading, error }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="req-modal-overlay">
      <div className="req-modal">
        {loading ? (
          <div className="modal-loading">
            <div className="spinner"></div>
            <p>Submitting your request...</p>
          </div>
        ) : error ? (
          <div>
            <h2 className="text-danger">Error</h2>
            <p className="text-danger error-message">{error}</p>
          </div>
        ) : (
          <div>
            <div className="success-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="checkmark-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h2>Request Submitted Successfully!</h2>
          </div>
        )}
        <button className="close-modal" onClick={onClose}>
          &times;
        </button>
      </div>
    </div>
  );
};

export default RequestModal;
