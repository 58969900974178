import React, { useState } from "react";
import Select from "../Select";
import Input from "../Input";
import "./stepper.css";
import IntInput from "../IntInput";
import CountrySelect from "../CountrySelect";
import {
  getCurrencySymbol,
  preferredCurrency,
} from "../../../Data/PrefferredCurrency";

const paymentMethodData = ["Credit/Debit Card", " PayPal "];
const typeOfGoods = ["general cargo", "perishables", " hazardous ", "goods"];
const shippingModeData = [
  "Full Container Load (FCL)",
  "Less-than-Container Load (LCL)",
];

export default function SeaFreightForm({
  handleSubmit,
  formData,
  setFormData,
}) {
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (errors[e.target.name]) {
      setErrors({ ...errors, [e.target.name]: "" });
    }
  };

  const validateStep = (currentStep) => {
    const newErrors = {};
    const requiredFields = {
      1: [
        "senderFirstName",
        "senderLastName",
        "senderPhoneNumber",
        "senderEmail",
        "senderCountry",
        "senderCity",
        "senderPostalCode",
        "senderStreet",
      ],
      2: [
        "receiverFirstName",
        "receiverLastName",
        "receiverPhoneNumber",
        "receiverEmail",
        "receiverCountry",
        "receiverCity",
        "receiverPostalCode",
        "receiverStreet",
      ],
      3: [
        "containerType",
        "typeOfGoods",
        "numberOfContainer",
        "weight",
        "valueOfGoods",
        "shippingDate",
        "destinationPort",
        "shippingMode",
        "preferredCurrency",
        "paymentMethod",
      ],
    };

    requiredFields[currentStep].forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);

    console.log(errors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (validateStep(step)) {
      setStep(step + 1);
    }
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    setStep(step - 1);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (validateStep(step)) {
      handleSubmit(formData);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="row ">
            <h4 className="mt-2 mb-4">Sender's Details</h4>
            <div className="col-lg-12 ">
              <div className="from-place mb-30 gap-3">
                <div className="origin-place">
                  <p className="uppercase">
                    First Name{" "}
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="text"
                    name="senderFirstName"
                    onChange={handleChange}
                    value={formData.senderFirstName || ""}
                  />
                  {errors.senderFirstName && (
                    <span className="error">{errors.senderFirstName}</span>
                  )}
                </div>
                <div className="origin-place">
                  <p className="uppercase">
                    Last Name{" "}
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="text"
                    name="senderLastName"
                    onChange={handleChange}
                    value={formData.senderLastName || ""}
                  />
                  {errors.senderLastName && (
                    <span className="error">{errors.senderLastName}</span>
                  )}
                </div>
                <div className="origin-place ">
                  <p className="uppercase">
                    Phone Number{" "}
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <IntInput
                    name="senderPhoneNumber"
                    onChange={handleChange}
                    value={formData.senderPhoneNumber || ""}
                  />
                  {errors.senderPhoneNumber && (
                    <span className="error">{errors.senderPhoneNumber}</span>
                  )}
                </div>
                <div className="origin-place">
                  <p className="uppercase">
                    Email Address{" "}
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="email"
                    name="senderEmail"
                    onChange={handleChange}
                    value={formData.senderEmail || ""}
                  />
                  {errors.senderEmail && (
                    <span className="error">{errors.senderEmail}</span>
                  )}
                </div>
                <div className="origin-place ">
                  <p>
                    Country{" "}
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>

                  <CountrySelect
                    value={formData.senderCountry || ""}
                    name="senderCountry"
                    onChange={handleChange}
                  />
                  {errors.senderCountry && (
                    <span className="error">{errors.senderCountry}</span>
                  )}
                </div>
                <div className="origin-place">
                  <p className="uppercase">City</p>
                  <Input
                    type="text"
                    name="senderCity"
                    onChange={handleChange}
                    value={formData.senderCity || ""}
                  />
                  {errors.senderCity && (
                    <span className="error">{errors.senderCity}</span>
                  )}
                </div>
                <div className="origin-place ">
                  <p>
                    Postal Code{" "}
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="text"
                    name="senderPostalCode"
                    onChange={handleChange}
                    value={formData.senderPostalCode || ""}
                  />
                  {errors.senderPostalCode && (
                    <span className="error">{errors.senderPostalCode}</span>
                  )}
                </div>
                <div className="origin-place">
                  <p className="uppercase">
                    Street{" "}
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="text"
                    name="senderStreet"
                    onChange={handleChange}
                    value={formData.senderStreet || ""}
                  />
                  {errors.senderStreet && (
                    <span className="error">{errors.senderStreet}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="row align-items-center">
            <h4 className="mt-2 mb-4">Receiver's Details</h4>

            <div className="col-lg-12 ">
              <div className="from-place mb-30 gap-3">
                <div className="origin-place">
                  <p className="uppercase">
                    First Name
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="text"
                    name="receiverFirstName"
                    onChange={handleChange}
                    value={formData.receiverFirstName || ""}
                  />
                  {errors.receiverFirstName && (
                    <span className="error">{errors.receiverFirstName}</span>
                  )}
                </div>
                <div className="origin-place">
                  <p className="uppercase">
                    Last Name
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="text"
                    name="receiverLastName"
                    onChange={handleChange}
                    value={formData.receiverLastName || ""}
                  />
                  {errors.receiverLastName && (
                    <span className="error">{errors.receiverLastName}</span>
                  )}
                </div>
                <div className="origin-place ">
                  <p className="uppercase">
                    Phone Number
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <IntInput
                    name="receiverPhoneNumber"
                    onChange={handleChange}
                    value={formData.receiverPhoneNumber || ""}
                  />
                  {errors.receiverPhoneNumber && (
                    <span className="error">{errors.receiverPhoneNumber}</span>
                  )}
                </div>
                <div className="origin-place">
                  <p className="uppercase">
                    Email Address
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="email"
                    name="receiverEmail"
                    onChange={handleChange}
                    value={formData.receiverEmail || ""}
                  />
                  {errors.receiverEmail && (
                    <span className="error">{errors.receiverEmail}</span>
                  )}
                </div>
                <div className="origin-place ">
                  <p>
                    Country
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <CountrySelect
                    name="receiverCountry"
                    value={formData.receiverCountry || ""}
                    onChange={handleChange}
                  />
                  {errors.receiverCountry && (
                    <span className="error">{errors.receiverCountry}</span>
                  )}
                </div>
                <div className="origin-place">
                  <p className="uppercase">
                    City
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="text"
                    name="receiverCity"
                    onChange={handleChange}
                    value={formData.receiverCity || ""}
                  />
                  {errors.receiverCity && (
                    <span className="error">{errors.receiverCity}</span>
                  )}
                </div>
                <div className="origin-place ">
                  <p>
                    Postal Code
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="text"
                    name="receiverPostalCode"
                    onChange={handleChange}
                    value={formData.receiverPostalCode || ""}
                  />
                  {errors.receiverPostalCode && (
                    <span className="error">{errors.receiverPostalCode}</span>
                  )}
                </div>
                <div className="origin-place">
                  <p className="uppercase">
                    Street
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="text"
                    name="receiverStreet"
                    onChange={handleChange}
                    value={formData.receiverStreet || ""}
                  />
                  {errors.receiverStreet && (
                    <span className="error">{errors.receiverStreet}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <>
            <h4 className="mt-2 mb-4">Package Details</h4>
            <div className="col-lg-12">
              <div className="from-place mb-30">
                <div className="origin-place">
                  <p className="uppercase">
                    Container Type
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="text"
                    name="containerType"
                    onChange={handleChange}
                    value={formData.containerType || ""}
                  />
                  {errors.containerType && (
                    <span className="error">{errors.containerType}</span>
                  )}
                </div>

                <div className="origin-place">
                  <p>
                    Type of Goods
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Select
                    data={typeOfGoods}
                    name="typeOfGoods"
                    onChange={handleChange}
                    value={formData.typeOfGoods || ""}
                  />
                  {errors.typeOfGoods && (
                    <span className="error">{errors.typeOfGoods}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="from-place mb-30">
                <div className="origin-place">
                  <p>
                    Number of Containers or Pallets
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="number"
                    name="numberOfContainer"
                    onChange={handleChange}
                    value={formData.numberOfContainer || ""}
                  />
                  {errors.numberOfContainer && (
                    <span className="error">{errors.numberOfContainer}</span>
                  )}
                </div>
                <div className="origin-place">
                  <p>
                    Weight (Kg)
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="number"
                    name="weight"
                    onChange={handleChange}
                    value={formData.weight || ""}
                  />
                  {errors.weight && (
                    <span className="error">{errors.weight}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="from-place mb-30">
                <div className="origin-place">
                  <p>
                    Preferred Shipping Date
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="date"
                    name="shippingDate"
                    onChange={handleChange}
                    value={formData.shippingDate || ""}
                  />
                  {errors.shippingDate && (
                    <span className="error">{errors.shippingDate}</span>
                  )}
                </div>
                <div className="origin-place ">
                  <p>
                    {" "}
                    Destination Port
                    <span className="error" style={{ fontSize: "18px" }}>
                      *
                    </span>
                  </p>
                  <Input
                    type="text"
                    name="destinationPort"
                    onChange={handleChange}
                    value={formData.destinationPort || ""}
                  />
                  {errors.destinationPort && (
                    <span className="error">{errors.destinationPort}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-12 " style={{ zIndex: "100" }}>
                <div className="from-place mb-30">
                  <div className="origin-place">
                    <p>
                      Shipping Mode
                      <span className="error" style={{ fontSize: "18px" }}>
                        *
                      </span>
                    </p>
                    <Select
                      data={shippingModeData}
                      name="shippingMode"
                      onChange={handleChange}
                      value={formData.shippingMode || ""}
                    />
                    {errors.shippingDate && (
                      <span className="error">{errors.shippingDate}</span>
                    )}
                  </div>
                  <div className="origin-place ">
                    <p>
                      Preferred Currency
                      <span className="error" style={{ fontSize: "18px" }}>
                        *
                      </span>
                    </p>
                    <Select
                      data={preferredCurrency}
                      name="preferredCurrency"
                      onChange={handleChange}
                      value={formData.preferredCurrency || ""}
                    />
                    {errors.preferredCurrency && (
                      <span className="error">{errors.preferredCurrency}</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="from-place mb-30">
                  <div className="origin-place">
                    <p>
                      Monetary Value of Goods
                      <span className="error" style={{ fontSize: "18px" }}>
                        *
                      </span>
                    </p>
                    <div className="currency-input-container">
                      <div className="currency-symbol">
                        {getCurrencySymbol(formData.preferredCurrency) ||
                          getCurrencySymbol("GBP")}
                      </div>
                      <Input
                        type="number"
                        name="valueOfGoods"
                        onChange={handleChange}
                        value={formData.valueOfGoods || ""}
                      />
                    </div>
                    {errors.valueOfGoods && (
                      <span className="error">{errors.valueOfGoods}</span>
                    )}
                  </div>
                  <div className="origin-place">
                    <p>
                      Payment Method
                      <span className="error" style={{ fontSize: "18px" }}>
                        *
                      </span>
                    </p>
                    <Select
                      data={paymentMethodData}
                      name="paymentMethod"
                      onChange={handleChange}
                      value={formData.paymentMethod || ""}
                    />
                    {errors.paymentMethod && (
                      <span className="error">{errors.paymentMethod}</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="origin-place">
                  <div className="me-4">
                    <p>Additional Info</p>
                    <textarea
                      name="additionalInfo"
                      onChange={handleChange}
                      value={formData.additionalInfo || ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const renderStepper = () => {
    return (
      <div className="stepper">
        {[1, 2, 3].map((stepNum) => (
          <span
            key={stepNum}
            className={`step ${step >= stepNum ? "active" : ""}`}
          ></span>
        ))}
      </div>
    );
  };

  return (
    <div className="quote-info-wrapper">
      {renderStepper()}
      {renderStep()}
      <div className="row align-items-center">
        <div className="col-6 col-lg-6 col-md-6">
          {step > 1 && (
            <div className="total-count-box mb-30 mt-30">
              <button
                className="theme_btn black-btn rounded-1"
                onClick={handlePrevious}
              >
                Previous
              </button>
            </div>
          )}
        </div>
        <div className="col-6 col-lg-6 col-md-6 text-end">
          <div className="total-count-box mb-30 mt-30 mr-20">
            {step < 3 ? (
              <button
                className="theme_btn black-btn rounded-1"
                onClick={handleNext}
              >
                Next
              </button>
            ) : (
              <button
                className="theme_btn black-btn rounded-1"
                onClick={(e) => handleSubmitForm(e)}
              >
                Request a Quote
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
