import React from "react";

const WhyChooseUs = () => {
  return (
    <section
      className="why-best-logistics position-relative pb-120 pt-md-60 pb-md-30 pt-xs-60 pb-xs-60"
      style={{
        position: "relative",
        paddingBottom: "120px",
        paddingTop: "60px",
      }}
    >
      <div className="container">
        <div
          className="row align-items-center c-services position-relative overflow-x-hidden"
          style={{
            overflow: "hidden",
            background: " #ffffff",
          }}
        >
          <div className="col-lg-7 col-md-7">
            <span
              className="app-tra-about-shape position-absolute "
              style={{
                width: "52%",
                left: "-100px",
                top: "0px",
                bottom: "0",
              }}
            >
              <img
                src="assets/img/main-truck.jpg"
                style={{ width: "100%", borderRadius: "20px" }}
                alt="logistics truck"
              />
            </span>
          </div>
          <div className="col-lg-5 col-md-5">
            <h2 className="mb-20">Why Choose Us?</h2>
            <p style={{ marginBottom: "10px" }}>
              Discover why businesses worldwide choose us as their preferred
              shipping solution, and experience the difference of shipping with
              confidence and efficiency.
            </p>
            <div className="app-tra-about-list ul-li-block">
              <ul style={{ paddingLeft: "0.5rem" }}>
                <li>Global network of 3PL Partners</li>
                <li>Deeply discounted shipping rates</li>
                <li>World-class technology</li>
                <li>Excellent customer experience</li>
                <li>Dedicated account manager</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
