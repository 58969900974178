import React, { useState } from "react";
import AirFreightForm from "../../../components/QuoteForm/Forms/AirFreightForm";
import CourierForm from "../../../components/QuoteForm/Forms/CourierForm";
import RailFreightForm from "../../../components/QuoteForm/Forms/RailFreightForm";
import RelocationForm from "../../../components/QuoteForm/Forms/RelocationForm";
import RoadTransportForm from "../../../components/QuoteForm/Forms/RoadTransportForm";
import SeaFreightForm from "../../../components/QuoteForm/Forms/SeaFreightForm";
import RequestModal from "../../../components/RequestModal";
import { toast, ToastContainer } from "react-toastify";
import { sendRequest } from "../../../services/RequestServices";

export default function GetQuotes() {
  const [formData, setFormData] = useState({
    service: "",
  });

  const [showRequestModal, setShowRequestModal] = useState(false);
  const [requestModalState, setRequestModalState] = useState({
    loading: false,
    error: null,
  });

  const handleChange = (e) => {
    const updatedField = { [e.target.name]: e.target.value };
    setFormData(updatedField);
  };

  const services = [
    { value: "courier", label: "Courier" },
    { value: "airFreight", label: "Air Freight" },
    { value: "railFreight", label: "Rail Freight" },
    { value: "seaFreight", label: "Sea Freight" },
    { value: "roadtransport", label: "Road Transport" },
    { value: "relocation", label: "Relocation" },
  ];

  const handleSubmit = async () => {
    console.log("Form Submitted: ", formData);
    setShowRequestModal(true);
    setRequestModalState({ loading: true, error: null });

    try {
      await sendRequest(formData.service, formData);

      setRequestModalState({ loading: false, error: null });
      setFormData({});
    } catch (error) {
      console.error(`Error submitting ${formData.service} request:`, error);
      setShowRequestModal(false);
      toast.error(`Error submitting ${formData.service} request:`, error);
    } finally {
      setRequestModalState({ loading: false, error: null });
    }
  };

  return (
    <div className="container-fluid mt-4">
      <ToastContainer />
      <div className="dashboard-title mb-4">
        <h1 className="page-title h2">Get a Quote</h1>
        <span className="text-muted">
          Easily request a quote for any service
        </span>
      </div>

      <div className="second-section">
        <form>
          <div className="row g-3">
            <div className="col-md-6">
              <label htmlFor="service" className="form-label">
                Select Service
              </label>
              <select
                id="service"
                name="service"
                value={formData.service}
                onChange={handleChange}
                className="form-select"
                style={{
                  border: "1px solid #ced4da",
                  borderRadius: "0.25rem",
                  padding: "0.375rem 0.75rem",
                  fontSize: "1rem",
                  lineHeight: "1.5",
                  color: "#495057",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                  transition:
                    "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                }}
              >
                <option value="">Select a service</option>
                {services.map((service) => (
                  <option key={service.value} value={service.value}>
                    {service.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-4">
            {formData?.service === "courier" && (
              <CourierForm
                handleSubmit={handleSubmit}
                formData={formData}
                setFormData={setFormData}
              />
            )}
            {formData?.service === "airFreight" && (
              <AirFreightForm
                handleSubmit={handleSubmit}
                formData={formData}
                setFormData={setFormData}
              />
            )}
            {formData?.service === "seaFreight" && (
              <SeaFreightForm
                handleSubmit={handleSubmit}
                formData={formData}
                setFormData={setFormData}
              />
            )}
            {formData?.service === "railFreight" && (
              <RailFreightForm
                handleSubmit={handleSubmit}
                formData={formData}
                setFormData={setFormData}
              />
            )}
            {formData?.service === "roadtransport" && (
              <RoadTransportForm
                handleSubmit={handleSubmit}
                formData={formData}
                setFormData={setFormData}
              />
            )}
            {formData?.service === "relocation" && (
              <RelocationForm
                handleSubmit={handleSubmit}
                formData={formData}
                setFormData={setFormData}
              />
            )}
          </div>
        </form>
      </div>

      {/* You can add the Table component here if needed */}

      <RequestModal
        show={showRequestModal}
        onClose={() => setShowRequestModal(false)}
        loading={requestModalState.loading}
        error={requestModalState.error}
      />
    </div>
  );
}
