import React from "react";
import { Link, useNavigate } from "react-router-dom";

const MainSlider = () => {
  const navigate = useNavigate();

  const handleServiceClick = (e) => {
    e.preventDefault();
    navigate(`/all-services`);
  };
  return (
    <section
      id="slider-two"
      style={{
        backgroundImage: `url('assets/img/background1.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingTop: "165px",
        paddingBottom: "200px",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
      className="slider-area pos-rel"
    >
      <div className="main-slider main-slider2">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-7">
              <div className="slider__content" style={{ marginTop: "100px" }}>
                <h5>Welcome to Shipperswise</h5>
                <h1 className="main-title">
                  One Stop International Freight Comparison
                  <br />
                  E-Market
                </h1>
                <h5>Instant Quotes, Simple Bookings, Track and Manage</h5>
                <Link onClick={handleServiceClick} className="theme_btn mt-5">
                  GET STARTED
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSlider;
