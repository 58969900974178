import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "./Table.css";
import { getCurrencySymbol } from "../../../Data/PrefferredCurrency";
import ShipmentModal from "./ShipmentModal";
import { shipmentDetails } from "../../../services/shipmentDetailsService";
import { toast } from "react-toastify";

const Table = ({ data, report }) => {
  const [filteredData, setFilteredData] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [shipmentDetailsData, setShipmentDetailsData] = useState(null);

  useEffect(() => {
    const results = data?.filter(
      (dataItem) =>
        dataItem?.shipment_id
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        dataItem?.shipment_type
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        dataItem?.date_created
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        dataItem?.delivery_status
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
    setFilteredData(results);
    setCurrentPage(1);
  }, [searchTerm, data]);

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData?.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleEntriesPerPageChange = (event) => {
    setEntriesPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handleShowModal = async (shipment) => {
    setIsModalLoading(true);
    setShowModal(true);
    try {
      const res = await shipmentDetails(shipment);
      console.log({ res });
      setShipmentDetailsData(res);
      setIsModalLoading(false);
    } catch (error) {
      console.log(error);
      setShowModal(false);
      toast.error(error);
    } finally {
      setIsModalLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShipmentDetailsData(null);
  };

  return (
    <div className="container-fluid mt-5">
      <div className="row mb-3">
        <div className="col-md-6">
          <div className="d-flex align-items-center">
            <label className="me-2">Show</label>
            <select
              className="form-select form-select-sm w-auto"
              value={entriesPerPage}
              onChange={handleEntriesPerPageChange}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <label className="ms-2">entries</label>
          </div>
        </div>
        <div className="col-md-3"></div>
        <div className="col-md-3">
          <div className="search-table">
            <span className="search-text">Search</span>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Shipment ID</th>
              <th>Amount due</th>
              <th>Shipment Type</th>
              <th>Delivery Status</th>
            </tr>
          </thead>
          <tbody>
            {currentEntries?.map((shipment, index) => (
              <tr key={shipment.shipment_id}>
                <td>{indexOfFirstEntry + index + 1}</td>
                <td>{shipment.date_created}</td>
                <td>
                  {shipment.shipment_id}
                  <br />
                  <Button
                    variant="link"
                    className="p-0"
                    onClick={() => handleShowModal(shipment?.shipment_id)}
                  >
                    All info
                  </Button>
                </td>
                <td className="text-capitalize">
                  {" "}
                  {shipment.amount_due && shipment.amount_due !== null ? (
                    <>
                      {getCurrencySymbol(shipment.preferred_currency)}{" "}
                      {shipment.amount_due}
                    </>
                  ) : (
                    <p className="text-italics fst-italic">pending quotation</p>
                  )}
                </td>
                <td className="text-capitalize">{shipment.shipment_type}</td>
                <td>
                  {shipment.delivery_status === "completed" && (
                    <div className={` c-pill c-pill--success `}>
                      {shipment.delivery_status}
                    </div>
                  )}
                  {shipment.delivery_status === "failed" && (
                    <div className={` c-pill c-pill--danger `}>
                      {shipment.delivery_status}
                    </div>
                  )}
                  {shipment.delivery_status === "declined" && (
                    <div className={` c-pill c-pill--danger `}>
                      {shipment.delivery_status}
                    </div>
                  )}
                  {shipment.delivery_status === "pending" && (
                    <div className={` c-pill c-pill--warning `}>
                      {shipment.delivery_status}
                    </div>
                  )}
                  {shipment.delivery_status === "in progress" && (
                    <div className={` c-pill c-pill--progress `}>
                      {shipment.delivery_status}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="row">
        <div className="col-md-6">
          <p>
            Showing {indexOfFirstEntry + 1} to{" "}
            {Math.min(indexOfLastEntry, filteredData?.length)} of{" "}
            {filteredData?.length} entries
          </p>
        </div>
        <div className="col-md-6">
          <nav>
            <ul className="pagination justify-content-end align-center-content">
              <li
                className={`page-item d-flex align-items-center ${
                  currentPage === 1 ? "disabled" : ""
                }`}
              >
                <i
                  className="material-icons-two-tone"
                  onClick={() => paginate(currentPage - 1)}
                >
                  chevron_left
                </i>
              </li>
              {Array.from({
                length: Math.ceil(filteredData?.length / entriesPerPage),
              }).map((_, index) => (
                <li
                  key={index}
                  className={`page-item pagination-item ${
                    currentPage === index + 1 ? "active-pagination" : ""
                  }`}
                >
                  <button
                    className="page-number"
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li
                className={`page-item d-flex align-items-center ${
                  currentPage ===
                  Math.ceil(filteredData?.length / entriesPerPage)
                    ? "disabled"
                    : ""
                }`}
              >
                <i
                  className="material-icons-two-tone"
                  onClick={() => paginate(currentPage + 1)}
                >
                  chevron_right
                </i>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {showModal && (
        <ShipmentModal
          isModalLoading={isModalLoading}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          shipment={shipmentDetailsData?.shipment}
          additional_details={shipmentDetailsData?.additional_details}
        />
      )}

      {showModal && <div className="modal-backdrop fade show"></div>}

      {/* <div
        className={`modal fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Shipment Information</h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleCloseModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {selectedShipment && (
                <div>
                  <p>
                    <strong>Shipment ID:</strong> {selectedShipment.shipmentID}
                  </p>
                  <p>
                    <strong>Transaction ID:</strong>{" "}
                    {selectedShipment.transactionID}
                  </p>
                  <p>
                    <strong>Date:</strong> {selectedShipment.date}
                  </p>
                  <p>
                    <strong>From:</strong> {selectedShipment.from}
                  </p>
                  <p>
                    <strong>Destination:</strong> {selectedShipment.destination}
                  </p>
                  <p>
                    <strong>Shipping Company:</strong>{" "}
                    {selectedShipment.shippingCompany}
                  </p>
                  <p>
                    <strong>Payment:</strong> {selectedShipment.payment}
                  </p>
                  <p>
                    <strong>Expected Delivery Date:</strong>{" "}
                    {selectedShipment.expectedDeliveryDate}
                  </p>
                  <p>
                    <strong>Status:</strong> {selectedShipment.status}
                  </p>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {showModal && <div className="modal-backdrop fade show"></div>} */}
    </div>
  );
};

export default Table;
