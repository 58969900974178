import React, { useEffect, useState } from "react";
import Table from "../Components/Table";

export default function Shipment({
  title,
  description,
  onTrack,
  shipmentData,
}) {
  // const [formData, setFormData] = useState({});
  const [shipmentFilterData, setFilterShipmentData] = useState([]);

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  useEffect(() => {
    switch (title) {
      case "All Shipments":
        setFilterShipmentData(shipmentData);
        break;
      case "Pending Shipments":
        const pendingData = shipmentData.filter(
          (item) => item.delivery_status === "pending"
        );
        setFilterShipmentData(pendingData);
        break;
      case "Failed Shipments":
        const failedData = shipmentData.filter(
          (item) => item.delivery_status === "failed"
        );
        setFilterShipmentData(failedData);
        break;
      case "Declined Shipments":
        const declinedData = shipmentData.filter(
          (item) => item.delivery_status === "declined"
        );
        setFilterShipmentData(declinedData);
        break;
      case "In Progress Shipments":
        const inProgressData = shipmentData.filter(
          (item) => item.delivery_status === "in progress"
        );
        setFilterShipmentData(inProgressData);
        break;
      case "Completed Shipments":
        const completedData = shipmentData.filter(
          (item) => item.delivery_status === "completed"
        );
        setFilterShipmentData(completedData);
        break;
      default:
        setFilterShipmentData(shipmentData);
        break;
    }
  }, [title, shipmentData]);

  return (
    <>
      <div className="dashboard-title">
        <h1 className="page-title">{title}</h1>

        <span>{description}</span>
      </div>
      <div className="second-section">
        {/* <ShipmentTable shipments={shipmentFilterData} /> */}
        <Table data={shipmentFilterData} />
      </div>
    </>
  );
}
