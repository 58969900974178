import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { getPendingData } from "../../Data/PendingData";
import { sendRequest } from "../../services/RequestServices";
import MainContent from "./Components/MainContent";
import Sidebar from "./Components/Sidebar";
import "./Layout.css";
import RequestModal from "../../components/RequestModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeMenuItem, setActiveMenuItem] = useState("dashboard");
  const menuRef = useRef(null);

  const [showRequestModal, setShowRequestModal] = useState(false);
  const [requestModalState, setRequestModalState] = useState({
    loading: false,
    error: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [iconsLoaded, setIconsLoaded] = useState(false);

  useEffect(() => {
    const checkAndSubmitRequest = async () => {
      setIsLoading(true);
      try {
        const pendingData = await getPendingData();
        const { pendingRequests, service } = pendingData;

        if (pendingRequests?.length && service) {
          setShowRequestModal(true);
          setRequestModalState({ loading: true, error: null });
          try {
            await sendRequest(service, pendingRequests);
            setRequestModalState({ loading: false, error: null });
            toast.success("Pending requests submitted successfully!");
          } catch (error) {
            console.error(`Error submitting ${service} request:`, error);
            setRequestModalState({
              loading: false,
              error: `Error submitting ${service} request.`,
            });
            toast.error(`Failed to submit pending ${service} requests.`);
          }
        }
      } catch (error) {
        console.error("Error fetching pending data:", error);
        toast.error("Failed to load pending data.");
      } finally {
        setIsLoading(false);
      }
    };

    checkAndSubmitRequest();
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Simulate icon loading
    const iconLoadTimeout = setTimeout(() => {
      setIconsLoaded(true);
    }, 1000); // Adjust this timeout as needed

    return () => clearTimeout(iconLoadTimeout);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  if (isLoading) {
    return (
      <div className="loading-overlay">
        <div className="loading-spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="app-container">
      <ToastContainer />
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        activeMenuItem={activeMenuItem}
        onMenuItemClick={handleMenuItemClick}
      />
      <div className={`main ${isSidebarOpen ? "sidebar-open" : ""}`}>
        <div className="top-nav">
          <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
            {iconsLoaded ? (
              <i
                className="material-icons icon toggle-icon"
                onClick={toggleSidebar}
                style={{ color: "#40475c", fontSize: "28px" }}
              >
                {isSidebarOpen
                  ? "format_indent_decrease"
                  : "format_indent_increase"}
              </i>
            ) : (
              <div className="icon-placeholder"></div>
            )}
          </div>

          <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
            <div className="nav-item dropdown hidden-on-mobile">
              <Link
                className="nav-link dropdown-toggle"
                to=""
                id="addDropdownLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {iconsLoaded ? (
                  <i
                    className="material-icons"
                    style={{ color: "#40475c", fontSize: "28px" }}
                  >
                    add
                  </i>
                ) : (
                  <div className="icon-placeholder"></div>
                )}
              </Link>
              <ul className="dropdown-menu" aria-labelledby="addDropdownLink">
                <li>
                  <Link
                    className="dropdown-item"
                    onClick={() => {
                      setActiveMenuItem("get-quotes");
                    }}
                  >
                    Request a Quote
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    onClick={() => {
                      setActiveMenuItem("parcel-tracking");
                    }}
                  >
                    Track a Shipment
                  </Link>
                </li>
              </ul>
            </div>

            {iconsLoaded ? (
              <i
                className="material-icons icon"
                style={{
                  color: "#40475c",
                  fontSize: "28px",
                  width: "fit-content",
                }}
              >
                support_agent
              </i>
            ) : (
              <div className="icon-placeholder"></div>
            )}
          </div>
        </div>

        <MainContent
          isSidebarOpen={isSidebarOpen}
          activeMenuItem={activeMenuItem}
          setActiveMenuItem={setActiveMenuItem}
        />
      </div>

      <RequestModal
        show={showRequestModal}
        onClose={() => setShowRequestModal(false)}
        loading={requestModalState.loading}
        error={requestModalState.error}
      />
    </div>
  );
};

export default Layout;
