import React, { useEffect, useState } from "react";
import Input from "../../../components/QuoteForm/Input";
import CountrySelect from "../../../components/QuoteForm/CountrySelect";
import IntInput from "../../../components/QuoteForm/IntInput";
import userService from "../../../services/userService";
import { toast, ToastContainer } from "react-toastify";
import CurrencySelect from "../Components/CurrencySelect";

const currencyMap = {
  GBP: { name: "Pounds", symbol: "£" },
  EUR: { name: "Euro", symbol: "€" },
  USD: { name: "Dollars", symbol: "$" },
};

export default function Profile() {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [preferredCurrency, setPreferredCurrency] = useState("");
  const [isEditingCurrency, setIsEditingCurrency] = useState(false);

  const fetchProfileInfo = async () => {
    try {
      setLoading(true);
      const data = await userService.getUserDetails();
      setFormData({
        firstName: data.first_name,
        lastName: data.last_name,
        phone: data.phone,
        email: data.email,
        country: data.country,
        city: data.city,
        postalCode: data.postal_code,
        street: data.street,
      });
    } catch (error) {
      toast.error("Failed to load profile information");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfileInfo();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCurrencyChange = (newCurrency) => {
    setPreferredCurrency(newCurrency);
  };

  useEffect(() => {
    const handleGetCurrency = async () => {
      try {
        const data = await userService.getPreferredCurrency();
        console.log("currency data, ", data);
        setPreferredCurrency(data?.preferred_currency);
      } catch (error) {
        toast.error("Failed to get preferred currency information");
      }
    };

    handleGetCurrency();
  }, [preferredCurrency]);

  const handleCurrencyUpdate = async () => {
    setUpdating(true);
    try {
      const data = await userService.updatePreferredCurrency({
        preferred_currency: preferredCurrency,
      });
      setPreferredCurrency(data?.preferred_currency);
      toast.success("Preferred currency updated successfully!");
      setIsEditingCurrency(false); // Return to view mode
    } catch (error) {
      toast.error("Failed to update preferred currency");
    } finally {
      setUpdating(false);
    }
  };

  const handleToggleEdit = () => {
    setIsEditingCurrency(!isEditingCurrency);
  };

  const validateForm = () => {
    const requiredFields = [
      "firstName",
      "lastName",
      "phone",
      "country",
      "city",
      "postalCode",
      "street",
    ];
    for (const field of requiredFields) {
      if (!formData[field]) {
        toast.error(
          `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`
        );
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return; // Prevent submission if validation fails
    }

    setUpdating(true);
    try {
      const userDetails = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        phone: formData.phone,
        country: formData.country,
        city: formData.city,
        postal_code: formData.postalCode,
        street: formData.street,
      };
      await userService.updateUserDetails(userDetails);
      toast.success("Profile updated successfully!");
    } catch (error) {
      toast.error("Failed to update profile");
    } finally {
      setUpdating(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="dashboard-title d-flex align-items-center justify-content-between">
        <div>
          <h1 className="page-title">Edit your profile</h1>
          <span>
            This page serves as a centralized hub for you to maintain and update
            your account details.
          </span>
        </div>
        <div>
          {/* <div className="d-flex flex-column align-items-center">
            <p className="mb-1 text-muted">Preferred Currency</p>
            <p className="fs-5 fw-bold " style={{ color: "#000066" }}>
              {currencyMap["GBP"]?.name} ({currencyMap["GBP"]?.symbol})
            </p>
          </div> */}
          {isEditingCurrency ? (
            <>
              <CurrencySelect
                selectedCurrency={preferredCurrency}
                onChange={handleCurrencyChange}
              />
              <div className="d-flex gap-2 align-content-center">
                <button
                  className="btn mt-2"
                  style={{ outline: "#000" }}
                  onClick={handleToggleEdit}
                >
                  Cancel
                </button>
                <button
                  className="btn theme_btn mt-2"
                  style={{ padding: "12px 24px" }}
                  onClick={handleCurrencyUpdate}
                  disabled={updating}
                >
                  {updating ? "Updating..." : "Save"}
                </button>
              </div>
            </>
          ) : (
            <>
              {preferredCurrency ? (
                <>
                  <span style={{ color: "#000066", fontWeight: "700" }}>
                    {currencyMap[preferredCurrency]?.name} (
                    {currencyMap[preferredCurrency]?.symbol})
                  </span>
                  <button className="btn btn-link" onClick={handleToggleEdit}>
                    Edit
                  </button>
                </>
              ) : (
                <>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100px", width: "100%" }}
                  >
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="second-section">
        <div className="row align-items-center mt-3">
          <div className="col-lg-12">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100px", width: "100%" }}
              >
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="from-place mb-30 gap-3">
                <>
                  <div className="origin-place">
                    <p className="uppercase">First Name</p>
                    <Input
                      type="text"
                      name="firstName"
                      onChange={handleChange}
                      value={formData.firstName || ""}
                    />
                  </div>
                  <div className="origin-place">
                    <p className="uppercase">Last Name</p>
                    <Input
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                      value={formData.lastName || ""}
                    />
                  </div>
                  <div className="origin-place">
                    <p className="uppercase">Phone Number</p>
                    <IntInput
                      name="phone"
                      onChange={handleChange}
                      value={formData.phone || ""}
                    />
                  </div>
                  <div className="origin-place">
                    <p className="uppercase">Email Address</p>
                    <Input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      value={formData.email || ""}
                      readOnly // Make the email field read-only
                    />
                  </div>
                  <div className="origin-place">
                    <p>Country</p>
                    <div className="quote-info-wrapper-div">
                      <CountrySelect
                        value={formData.country || ""}
                        name="country"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="origin-place">
                    <p className="uppercase">City</p>
                    <Input
                      type="text"
                      name="city"
                      onChange={handleChange}
                      value={formData.city || ""}
                    />
                  </div>
                  <div className="origin-place">
                    <p>Postal Code</p>
                    <Input
                      type="text"
                      name="postalCode"
                      onChange={handleChange}
                      value={formData.postalCode || ""}
                    />
                  </div>
                  <div className="origin-place">
                    <p className="uppercase">Street</p>
                    <Input
                      type="text"
                      name="street"
                      onChange={handleChange}
                      value={formData.street || ""}
                    />
                  </div>
                </>
              </div>
            )}
            <button
              className="theme_btn rounded-1"
              onClick={handleSubmit}
              disabled={updating}
            >
              {updating ? "Updating..." : "Update Profile"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
