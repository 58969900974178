import React from "react";

export default function CourierDetailsModal({
  shipment,
  handleCloseModal,
  additional_details,
  showModal,
}) {
  return (
    <>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content border-0 shadow-lg">
            <div className="modal-header modal-bg text-white">
              <h5 className="modal-title fs-4 fw-bold">
                Courier Shipment - {shipment.shipment_id}
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                onClick={handleCloseModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-4">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="card h-100 border-0 shadow-sm">
                    <div className="card-body">
                      <h6 className="card-title text-primary mb-3">
                        Shipment Information
                      </h6>
                      <p className="mb-2">
                        <strong>Date Created:</strong>{" "}
                        {new Date(shipment.date_created).toLocaleString()}
                      </p>
                      <p className="mb-2">
                        <strong>Shipment Type:</strong> {shipment.shipment_type}
                      </p>
                      <p className="mb-2">
                        <strong>Delivery Status:</strong>{" "}
                        <span className="badge bg-warning text-dark">
                          {shipment.delivery_status}
                        </span>
                      </p>
                      <p className="mb-2">
                        <strong>Amount Due:</strong>{" "}
                        {shipment.amount_due || "N/A"}
                      </p>
                      <p className="mb-2">
                        <strong>Service Type:</strong>{" "}
                        {additional_details.service_type}
                      </p>
                      <p className="mb-2">
                        <strong>Pick-up Date:</strong>{" "}
                        {new Date(
                          additional_details.pick_up_date
                        ).toLocaleString()}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card h-100 border-0 shadow-sm">
                    <div className="card-body">
                      <h6 className="card-title text-primary mb-3">
                        Package Details
                      </h6>
                      <p className="mb-2">
                        <strong>Number of Packages:</strong>{" "}
                        {additional_details.number_of_packages}
                      </p>
                      <p className="mb-2">
                        <strong>Package Description:</strong>{" "}
                        {additional_details.package_description}
                      </p>
                      <p className="mb-2">
                        <strong>Weight:</strong> {additional_details.weight} kg
                      </p>
                      <p className="mb-2">
                        <strong>Dimensions:</strong> {additional_details.length}{" "}
                        x {additional_details.width} x{" "}
                        {additional_details.height} cm
                      </p>
                      <p className="mb-2">
                        <strong>Payment Method:</strong>{" "}
                        {additional_details.payment_method}
                      </p>
                      <p className="mb-2">
                        <strong>Preferred Currency:</strong>{" "}
                        {additional_details.preferred_currency}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card h-100 border-0 shadow-sm">
                    <div className="card-body">
                      <h6 className="card-title text-primary mb-3">
                        Sender Information
                      </h6>
                      <p className="mb-1">
                        <strong>Name:</strong>{" "}
                        {additional_details.sender_first_name}{" "}
                        {additional_details.sender_last_name}
                      </p>
                      <p className="mb-1">
                        <strong>Email:</strong>{" "}
                        {additional_details.sender_email}
                      </p>
                      <p className="mb-1">
                        <strong>Phone:</strong>{" "}
                        {additional_details.sender_phone}
                      </p>
                      <p className="mb-1">
                        <strong>Address:</strong>{" "}
                        {additional_details.sender_street_address}
                      </p>
                      <p className="mb-1">
                        <strong>City:</strong> {additional_details.sender_city}
                      </p>
                      <p className="mb-1">
                        <strong>Country:</strong>{" "}
                        {additional_details.sender_country}
                      </p>
                      <p className="mb-1">
                        <strong>Postal Code:</strong>{" "}
                        {additional_details.sender_postal_code}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card h-100 border-0 shadow-sm">
                    <div className="card-body">
                      <h6 className="card-title text-primary mb-3">
                        Receiver Information
                      </h6>
                      <p className="mb-1">
                        <strong>Name:</strong>{" "}
                        {additional_details.receiver_first_name}{" "}
                        {additional_details.receiver_last_name}
                      </p>
                      <p className="mb-1">
                        <strong>Email:</strong>{" "}
                        {additional_details.receiver_email}
                      </p>
                      <p className="mb-1">
                        <strong>Phone:</strong>{" "}
                        {additional_details.receiver_phone}
                      </p>
                      <p className="mb-1">
                        <strong>Address:</strong>{" "}
                        {additional_details.receiver_street_address}
                      </p>
                      <p className="mb-1">
                        <strong>City:</strong>{" "}
                        {additional_details.receiver_city}
                      </p>
                      <p className="mb-1">
                        <strong>Country:</strong>{" "}
                        {additional_details.receiver_country}
                      </p>
                      <p className="mb-1">
                        <strong>Postal Code:</strong>{" "}
                        {additional_details.receiver_postal_code}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
