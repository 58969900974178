import React, { useState } from "react";
import "./LoginPage.css";
import AuthPageLayout from "./AuthPageLayout";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const LoginPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is invalid";

    if (!formData.password) newErrors.password = "Password is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Sign-ip logic
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const userData = {
      email: formData.email,
      password: formData.password,
    };

    console.log(userData);

    setLoading(true);
    setSuccessMessage("");

    try {
      const response = await axios.post(
        "https://api.shipperswise.com/auth/login",
        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Login successfully!");
      setFormData({
        email: "",
        password: "",
      });

      console.log("response ", response);

      localStorage.setItem("token", response.data.token);
      navigate("/app");
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to login");
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthPageLayout>
      <ToastContainer />
      <div className="login-form ">
        <h1 className="mb-3 text-center login-text">Login</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p className="text-danger">{errors.email}</p>}
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            {errors.password && (
              <p className="text-danger">{errors.password}</p>
            )}
          </div>
          <div className="mb-3">
            <Link to="/forgot-password" className="forgot-password">
              Forgot Password
            </Link>
          </div>

          {errors.apiError && <p className="text-danger">{errors.apiError}</p>}
          {successMessage && <p className="text-success">{successMessage}</p>}
          <button
            type="submit"
            className="btn btn-primary w-100 mb-3"
            disabled={loading}
          >
            {loading ? "Login..." : "Log in"}
          </button>
          <p className="text-center mb-3 form-label">Or</p>
          <button
            type="button"
            className=" w-100 d-flex align-items-center justify-content-center login-with-google"
          >
            <img
              src="/assets/img/Google.svg"
              alt="Google Icon"
              className="google-icon me-3"
            />
            Login with google
          </button>
          <p className="text-center mt-3">
            Don't have an account?{" "}
            <Link to="/signup" className="create-account">
              Create account
            </Link>
          </p>
        </form>
      </div>
    </AuthPageLayout>
  );
};

export default LoginPage;
