export const preferredCurrency = ["GBP", "EUR", "USD"];

export const currencyMap = {
  GBP: { name: "British Pounds", symbol: "£" },
  EUR: { name: "Euro", symbol: "€" },
  USD: { name: "US Dollars", symbol: "$" },
};

export const getCurrencySymbol = (currencyCode) => {
  const currency = currencyMap[currencyCode];
  return currency ? currency.symbol : "";
};
