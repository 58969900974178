import React from "react";

export default function RelocationDetailsModal({
  showModal,
  handleCloseModal,
  shipment,
  additional_details,
}) {
  return (
    <>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content border-0 shadow-lg">
            <div className="modal-header modal-bg text-white">
              <h5 className="modal-title fs-4 fw-bold">
                Shipment Details - {shipment?.shipment_id}
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                onClick={handleCloseModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-4">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="card h-100 border-0 shadow-sm">
                    <div className="card-body">
                      <h6 className="card-title text-primary mb-3">
                        Shipment Information
                      </h6>
                      <p className="mb-2">
                        <strong>Date Created:</strong>{" "}
                        {new Date(shipment?.date_created).toLocaleString()}
                      </p>
                      <p className="mb-2">
                        <strong>Shipment Type:</strong>{" "}
                        {shipment?.shipment_type}
                      </p>
                      <p className="mb-2">
                        <strong>Delivery Status:</strong>{" "}
                        <span className="badge bg-warning text-dark">
                          {shipment?.delivery_status}
                        </span>
                      </p>
                      <p className="mb-2">
                        <strong>Amount Due:</strong>{" "}
                        {shipment?.amount_due || "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card h-100 border-0 shadow-sm">
                    <div className="card-body">
                      <h6 className="card-title text-primary mb-3">
                        Relocation Details
                      </h6>
                      <p className="mb-2">
                        <strong>Type:</strong>{" "}
                        {additional_details?.type_of_relocation}
                      </p>
                      <p className="mb-2">
                        <strong>Items:</strong>{" "}
                        {additional_details?.list_of_items}
                      </p>
                      <p className="mb-2">
                        <strong>Preferred Moving Date:</strong>{" "}
                        {additional_details?.preferred_moving_date}
                      </p>
                      <p className="mb-2">
                        <strong>Payment Method:</strong>{" "}
                        {additional_details?.payment_method}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card h-100 border-0 shadow-sm">
                    <div className="card-body">
                      <h6 className="card-title text-primary mb-3">From</h6>
                      <p className="mb-1">
                        <strong>Country:</strong>{" "}
                        {additional_details?.from_country}
                      </p>
                      <p className="mb-1">
                        <strong>City:</strong> {additional_details?.from_city}
                      </p>
                      <p className="mb-1">
                        <strong>Postal Code:</strong>{" "}
                        {additional_details?.from_postal_code}
                      </p>
                      <p className="mb-1">
                        <strong>Address:</strong>{" "}
                        {additional_details?.from_address}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card h-100 border-0 shadow-sm">
                    <div className="card-body">
                      <h6 className="card-title text-primary mb-3">To</h6>
                      <p className="mb-1">
                        <strong>Country:</strong>{" "}
                        {additional_details?.to_country}
                      </p>
                      <p className="mb-1">
                        <strong>City:</strong> {additional_details?.to_city}
                      </p>
                      <p className="mb-1">
                        <strong>Postal Code:</strong>{" "}
                        {additional_details?.to_postal_code}
                      </p>
                      <p className="mb-1">
                        <strong>Address:</strong>{" "}
                        {additional_details?.to_address}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
