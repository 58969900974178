import React from "react";
import { Link, useNavigate } from "react-router-dom";

const CallToAction = () => {
  const navigate = useNavigate();

  const handleServiceClick = (e) => {
    e.preventDefault();
    navigate(`/all-services`);
  };

  return (
    <section className="call-to-action pt-150 pb-120 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">
      <img
        className="cta-shape cta-one d-none d-md-inline-block"
        src="assets/img/shape/eclip-3.svg"
        alt="Cta Shape"
      />
      <img
        className="cta-shape cta-two"
        src="assets/img/shape/box-2.svg"
        alt="Cta Shape"
      />
      <div className="container">
        <div className="cta-border">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-7">
              <div className="cta-title mb-30">
                <h2 style={{ fontSize: "40px" }}>
                  Let us handle your logistics, so you can ship smarter and
                  thrive in a connected world.
                </h2>
                <Link
                  className="theme_btn black-btn cta-btn"
                  onClick={handleServiceClick}
                >
                  Get Started
                </Link>
              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="chat-manager text-center text-lg-end mb-30">
                <img
                  src="assets/img/logisticsx.png"
                  className="w-100"
                  alt="Logistics"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
