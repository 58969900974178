import React from "react";

export default function Quotes({ shipmentData }) {
  return (
    <>
      <div
        className="coming-soon-container d-flex flex-column align-items-center justify-content-center text-center"
        style={{ marginTop: "200px" }}
      >
        <h1 className="display-4">Quotes Page Coming Soon!</h1>
        <p className="lead">
          This section will soon allow you to track and manage all your
          shipments with ease.
        </p>
        <p className="text-muted mt-3">Stay tuned for updates!</p>
      </div>
    </>
  );
}
