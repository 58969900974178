import React, { useState } from "react";

const currencyMap = {
  GBP: { name: "Pounds", symbol: "£" },
  EUR: { name: "Euro", symbol: "€" },
  USD: { name: "Dollars", symbol: "$" },
};

const CurrencySelect = ({ selectedCurrency, onChange }) => {
  const [currency, setCurrency] = useState(selectedCurrency);

  const handleCurrencyChange = (e) => {
    const newCurrency = e.target.value;
    setCurrency(newCurrency);
    console.log({ newCurrency });
    onChange(newCurrency);
  };

  return (
    <select
      value={currency}
      onChange={handleCurrencyChange}
      className="form-select"
    >
      {Object.keys(currencyMap).map((code) => (
        <option key={code} value={code}>
          {currencyMap[code].name} ({currencyMap[code].symbol})
        </option>
      ))}
    </select>
  );
};

export default CurrencySelect;
