import React, { useState } from "react";
import Logo from "../../../components/ui/Logo";
import "./Sidebar.css";
import { useNavigate } from "react-router-dom";

const Sidebar = ({
  isOpen,
  toggleSidebar,
  activeMenuItem,
  onMenuItemClick,
}) => {
  const navigate = useNavigate();
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const handleMenuItemClick = (menuItem) => {
    if (menuItem.id === "logout") {
      localStorage.removeItem("token");
      navigate("/login");
    } else if (menuItem.subItems) {
      setOpenSubMenu(openSubMenu === menuItem.id ? null : menuItem.id);
    } else {
      onMenuItemClick(menuItem.id);
      setOpenSubMenu(null);
    }
  };

  const menuItems = [
    { id: "dashboard", icon: "dashboard", label: "Dashboard" },
    { id: "get-quotes", icon: "request_quote", label: "Request Quote" },
    {
      id: "shipments",
      icon: "local_shipping",
      label: "Shipments",
      subItems: [
        { id: "all-shipments", label: "All Shipments" },
        { id: "pending-shipments", label: "Pending Shipments" },
        { id: "in-progress-shipments", label: "In Progress Shipments" },
        { id: "completed-shipments", label: "Completed Shipments" },
        { id: "failed-shipments", label: "Failed Shipments" },
        { id: "declined-shipments", label: "Declined Shipments" },
      ],
    },
    { id: "parcel-tracking", icon: "search", label: "Parcel Tracking" },
    { id: "quotes", icon: "description", label: "Quotation" },
    { id: "profile", icon: "person", label: "Profile" },
    {
      id: "email-notification",
      icon: "notifications",
      label: "Email Notifications",
    },
    { id: "change-password", icon: "lock", label: "Change Password" },
    { id: "logout", icon: "logout", label: "Logout" },
  ];

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="sidebar-header">{isOpen ? <Logo /> : <Logo small />}</div>
      <ul className="sidebar-menu">
        {menuItems.map((item) => (
          <React.Fragment key={item.id}>
            <li
              className={`${activeMenuItem === item.id ? "active" : ""} ${
                item.subItems ? "has-submenu" : ""
              }`}
              onClick={() => handleMenuItemClick(item)}
            >
              <i className="material-icons-two-tone">{item.icon}</i>
              {isOpen && <span>{item.label}</span>}
              {item.subItems && (
                <i
                  className={`material-icons-two-tone ms-auto ${
                    openSubMenu === item.id ? "rotate" : ""
                  }`}
                >
                  {openSubMenu === item.id ? "expand_less" : "expand_more"}
                </i>
              )}
            </li>
            {item.subItems && openSubMenu === item.id && (
              <ul className="sub-menu">
                {item.subItems.map((subItem) => (
                  <li
                    key={subItem.id}
                    onClick={() => onMenuItemClick(subItem.id)}
                    className={activeMenuItem === subItem.id ? "active" : ""}
                  >
                    {subItem.label}
                  </li>
                ))}
              </ul>
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
