import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import React from "react";
import loader from "../../assets/animation/loader-dotlottie.lottie";
import "./Loader.css"; // Add any styles you need for the loader

const Loader = () => {
  return (
    <div className="loader-container">
      <DotLottieReact
        src={loader} // Replace with your actual .lottie file path or URL
        loop
        autoplay
        style={{ width: 200, height: 200 }}
      />
    </div>
  );
};

export default Loader;
