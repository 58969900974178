import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../../components/QuoteForm/Input";
import userService from "../../../services/userService";

export default function ChangePassword() {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangePassword = async () => {
    // Basic validation
    if (
      !formData.currentPassword ||
      !formData.newPassword ||
      !formData.repeatNewPassword
    ) {
      toast.error("All fields are required");
      return;
    }
    if (formData.newPassword.length < 6) {
      toast.error("New password must be at least 6 characters long");
      return;
    }
    if (formData.newPassword !== formData.repeatNewPassword) {
      toast.error("New passwords do not match");
      return;
    }

    const userData = {
      current_password: formData.currentPassword,
      new_password: formData.newPassword,
      new_password_confirmation: formData.repeatNewPassword,
    };

    setLoading(true); // Set loading state

    try {
      const data = await userService.changeUserPassword(userData);
      toast.success("Password changed successfully");
      console.log("Password changed successfully:", data);
      setFormData({}); // Clear form on success
    } catch (error) {
      toast.error(error.response.data.error);
      console.error("Failed to change password", error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="dashboard-title">
        <h1 className="page-title">Change your password</h1>
        <span>
          This page helps users maintain the security of your account by
          allowing you to update your password regularly.
        </span>
      </div>
      <div className="dashboard-title">
        <div className="row align-items-center mt-3">
          <div className="col-lg-12 ">
            <div className="from-place mb-30 gap-3">
              <div className="origin-place">
                <p className="uppercase">Current Password</p>
                <Input
                  type="password"
                  name="currentPassword"
                  onChange={handleChange}
                  value={formData.currentPassword || ""}
                />
              </div>
              <div className="origin-place">
                <p className="uppercase">New Password</p>
                <Input
                  type="password"
                  name="newPassword"
                  onChange={handleChange}
                  value={formData.newPassword || ""}
                />
              </div>
              <div className="origin-place">
                <p className="uppercase">Repeat New Password</p>
                <Input
                  type="password"
                  name="repeatNewPassword"
                  onChange={handleChange}
                  value={formData.repeatNewPassword || ""}
                />
              </div>
            </div>
            <button
              className="theme_btn rounded-1"
              onClick={handleChangePassword}
              disabled={loading} // Disable button if loading
            >
              {loading ? "Changing Password..." : "Change Password"}{" "}
              {/* Loading text */}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
